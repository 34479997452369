@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.mar {
  font-family: "Marcellus", serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.hero-bg {
  background-repeat: no-repeat;
  background-image: url(../public/images/hero-bg.png);
  height: 100%;
  background-position: center;
  background-size: cover;
}

.padding {
  padding-left: 180px;
  padding-right: 180px;
}

.paddingLeft {
  padding-left: 180px;
}
.paddingRight {
  padding-right: 180px;
}
.transition {
  transition: 0.4s ease;
}
.nav-link:hover .nav-link-num {
  color: #f2994a;
}

.scrollbar::-webkit-scrollbar {
  height: 4px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #70603c;
  border-radius: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #d1cbc0;
  margin-right: 300px;
  margin-left: 300px;
}

.scrollbar1::-webkit-scrollbar {
  height: 4px;
}
.scrollbar1::-webkit-scrollbar-thumb {
  background-color: #f2994a;
  border-radius: 4px;
}

.scrollbar1::-webkit-scrollbar-track {
  background-color: #d1cbc0;
}

/* new */

.scrollbar3::-webkit-scrollbar {
  height: 4px;
}
.scrollbar3::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 4px;
}

.scrollbar3::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0);
}
.bg-explore-first {
  background-image: url(../public/images/explore-bg1.png);
  min-height: 846px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blur-surface1 {
  backdrop-filter: blur(10px);
  background: linear-gradient(
      0deg,
      rgba(232, 195, 129, 0.38) 0%,
      rgba(232, 195, 129, 0.38) 100%
    ),
    rgba(119, 119, 119, 0.5);
}

.bg-explore-second {
  background-image: url(../public/images/explore-bg2.png);
  min-height: 846px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blur-surface2 {
  background: linear-gradient(
      0deg,
      rgba(232, 195, 129, 0.2) 0%,
      rgba(232, 195, 129, 0.2) 100%
    ),
    rgba(119, 119, 119, 0.5);

  backdrop-filter: blur(10px);
}

.bg-explore-third {
  background-image: url(../public/images/explore-bg3.png);
  min-height: 846px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blur-surface3 {
  background: linear-gradient(
      0deg,
      rgba(232, 195, 129, 0.38) 0%,
      rgba(232, 195, 129, 0.38) 100%
    ),
    rgba(119, 119, 119, 0.5);

  backdrop-filter: blur(10px);
}

.exceed-bg {
  background-image: url(../public/images/exceed-bg.png),
    url(../public/images/exceed-overlay.png);
  background-size: cover;
  width: 100%;
  background-color: linear-gradient(
      0deg,
      rgba(242, 153, 74, 0.31) 0%,
      rgba(242, 153, 74, 0.31) 100%
    ),
    linear-gradient(180deg, rgba(67, 56, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-blend-mode: multiply;
}

.social_icon:hover {
  transform: translateY(-5px);
}

.nav-menu {
  backdrop-filter: blur(20px);
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);
}

.about-bg {
  background-image: url(../public/exterior/ex4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.4);
}

.blog-bg {
  background-image: url(../public/images/blog-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.booking-bg {
  background-image: url(../public/images/booking-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.gallery-bg {
  background-image: url(../public/exterior/ex3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.4);
}

.restaurant-bg {
  background-image: url(../public/restaurant/restaurant2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.4);
}

.events-bg {
  background-image: url(../public/events/event2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  background: rgba(0, 0, 0, 0.7);
}
.modal2 {
  background: rgba(0, 0, 0, 0.5);
}

.line {
  background: linear-gradient(270deg, #f13e3e 31.01%, #f7d15f 82.2%);
  height: 8px;
}
@media screen and (max-width: 1600px) {
  .padding {
    padding-left: 100px;
    padding-right: 100px;
  }
  .paddingLeft {
    padding-left: 100px;
  }
  .paddingRight {
    padding-right: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .padding {
    padding-left: 60px;
    padding-right: 60px;
  }
  .paddingLeft {
    padding-left: 60px;
  }
  .paddingRight {
    padding-right: 60px;
  }
}

@media screen and (max-width: 1000px) {
  .padding {
    padding-left: 40px;
    padding-right: 40px;
  }
  .paddingLeft {
    padding-left: 40px;
  }

  .paddingRight {
    padding-right: 40px;
  }
  .scrollbar::-webkit-scrollbar-track {
    background-color: #d1cbc0;
    margin-right: 100px;
    margin-left: 100px;
  }
}

@media screen and (max-width: 650px) {
  .padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .paddingLeft {
    padding-left: 1rem;
  }
  .paddingRight {
    padding-right: 1rem;
  }
  .scrollbar::-webkit-scrollbar-track {
    background-color: #d1cbc0;
    margin-right: 50px;
    margin-left: 50px;
  }

  .app__gallery__arrows {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Styles */
.app__gallery__images {
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
}

.app__gallery__images__container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.app__gallery__image__card {
  min-width: 100vw;
  height: 70vh;
  position: relative;
  transition: 0.2s;
}
.app__gallery__image__card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.instagram__icon {
  color: white;
  opacity: 0.3;
  transition: 0.4s ease;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
}

.app__gallery__arrows {
  position: absolute;
  bottom: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.arrow {
  cursor: pointer;
}

/* Min Height */

.slider-container {
  display: flex;
  animation: slide-animation 20s linear infinite;
}

@keyframes slide-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slider:hover .slider-container {
  animation-play-state: paused;
}

/* Text */
.p1 {
  animation-name: text1;
  animation-delay: 1s;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
.p2 {
  animation-name: text1;
  animation-delay: 2s;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
.p3 {
  animation-name: text1;
  animation-delay: 3s;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
.p4 {
  animation-name: text1;
  animation-delay: 4s;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes text1 {
  0% {
    color: white;
  }
  100% {
    color: rgba(255, 255, 255, 0.3);
  }
}
.bg-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Matches the container's height */
  overflow: hidden;
  z-index: -1; /* Keeps it behind the content */
}

.bg-slider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: slide-animation 16s infinite;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.bg-slider img:nth-child(1) {
  animation-delay: 0s;
}
.bg-slider img:nth-child(2) {
  animation-delay: 4s;
}
.bg-slider img:nth-child(3) {
  animation-delay: 8s;
}
.bg-slider img:nth-child(4) {
  animation-delay: 12s;
}

@keyframes slide-animation {
  0%,
  25% {
    opacity: 1;
  }
  26%,
  100% {
    opacity: 0;
  }
}
